<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 14:00:35
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-01-26 10:10:53
 * @FilePath: \app_wb\src\views\News.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="bny_news-list">
      <div class="bny_news-list-left">
        <div class="bny_news-list-left-top">内网平台</div>
        <div class="bny_news-list-left-items">
          <ul class="list_news-items">
            <li
              v-for="(item, index) in list_items"
              :key="index"
              @click="clickListItem($event, item.name)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="bny_news-list-right">
        <div class="bny_news-list-right-top">
          您当前的位置：<span v-html="current_addr"></span>
          <div style="float: right; padding-right: 10px">
            <el-button
              type="text"
              @click="showSeachBox"
              v-show="showSearchButton"
              >查询搜索</el-button
            >
          </div>
        </div>

        <div
          class="bny_news-list-right-content"
          style="display: flex; flex-direction: row"
        >
          <div
            v-if="!showGzdept"
            style="height: auto; padding: 1px; padding-top: 20px"
          >
            <el-radio-group
              v-model="radioDept"
              @change="handleRadioChange"
              size="medium"
              border
              style="display: flex; flex-direction: column"
            >
              <el-radio-button
                :label="item.label"
                v-for="(item, index) in ruleIsmDepts"
                :key="'dept_' + index"
              ></el-radio-button>
            </el-radio-group>
          </div>
          <div :style="getContentStyle">
            <ul>
              <li
                v-for="(item, index) in list_data"
                :key="index"
                @click="showDetail(item)"
              >
                <div class="nwxw-list-item" v-show="showWlNTrurOrFalse">
                  <div class="bny_news-list-right-content-left">
                    <!-- <img :src="item.thumbnail" alt="" /> -->
                    <el-image
                      style="width: 280px; height: 160px"
                      :src="item.thumbnail"
                      fit="cover"
                    >
                    </el-image>
                  </div>
                  <div class="bny_news-list-right-content-right">
                    <div
                      class="content-title"
                      style="
                        font-size: 16px;
                        line-height: 24px;
                        padding-top: 12px;
                      "
                    >
                      {{ item.title }}
                    </div>
                    <div
                      v-html="item.content"
                      style="height: 90px; line-height: 34px; font-size: 14px"
                    ></div>
                    <div>
                      时间：{{
                        item.createTime
                      }}&nbsp;&nbsp;&nbsp;&nbsp;发文部门：{{ item.deptName }}
                    </div>
                  </div>
                </div>
                <div class="xmzx-list-item" v-show="showWlTrurOrFalse">
                  <el-row>
                    <el-col
                      :span="!showGzdept ? 20 : 16"
                      style="
                        text-align: left;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      "
                      v-if="item.title"
                    >
                      {{ item.title }}
                    </el-col>

                    <el-col
                      :span="6"
                      style="
                        text-align: left;
                        white-space: pre-wrap;
                        word-break: break-all;
                        word-wrap: break-word;
                      "
                      v-if="item.deptName"
                      v-show="showGzdept"
                    >
                      {{ item.deptName.substring(0, 12) }}
                    </el-col>

                    <el-col
                      :span="!showGzdept ? 4 : 2"
                      style="text-align: center"
                    >
                      {{ item.createTime }}
                    </el-col>
                  </el-row>
                </div>
              </li>
            </ul>
            <div style="clear: both"></div>
            <el-pagination
              v-show="showOrFalse"
              background
              layout="total,prev, pager, next"
              :total="total"
              @current-change="currentChange"
              :page-size="pageSize"
              style="margin: 20px 0px"
            >
            </el-pagination>
          </div>

          <!-- 综合服务 -->
          <div v-show="showContentOrFalse" style="width: 100%">
            <el-row
              v-for="(item, index) in plat_types"
              :key="index"
              style="margin: 40px 0"
            >
              <div
                class="zhfw-title"
                style="
                  height: 40px;
                  line-height: 40px;
                  border-bottom: 1px solid #ddd;
                  font-size: 16px;
                  font-weight: 600;
                  text-align: left;

                  padding-left: 20px;
                "
              >
                {{ item.typename }}
              </div>
              <div class="zhfw-list-items">
                <div
                  class="block"
                  v-for="(row, index) in item.platServices"
                  :key="index"
                  style="margin: 10px 20px; cursor: pointer"
                  @click="goTo(row.url)"
                >
                  <el-image
                    style="
                      width: 45px;
                      height: 45px;
                      border-radius: 15px;
                      margin-bottom: 5px;
                    "
                    :src="row.thumbnail"
                    fit="fill"
                  ></el-image>
                  <div class="demonstration" style="font-size: 16px">
                    {{ row.name }}
                  </div>
                </div>
              </div>
            </el-row>
          </div>
        </div>
      </div>
    </div>

    <!-- 查询搜索 -->
    <el-drawer
      title="查询搜索文章"
      :visible.sync="drawer"
      :with-header="false"
      :size="400"
      :modal="false"
    >
      <!-- 标题、内容、作者、部门、年份 -->
      <div style="padding: 180px 20px 0px 20px; text-align: left">
        <el-form ref="form" :model="searchForm" label-width="60px">
          <el-form-item label="标题">
            <el-input clearable v-model="searchForm.s_title"></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input clearable v-model="searchForm.s_content"></el-input>
          </el-form-item>

          <el-form-item label="作者" v-show="s_author_show">
            <el-input clearable v-model="searchForm.s_author"></el-input>
          </el-form-item>
          <el-form-item label="发布部门" label-width="100">
            <!-- <el-input clearable v-model="searchForm.s_dept"></el-input> -->
            <el-select
              clearable
              v-model="searchForm.s_dept"
              filterable
              placeholder="请选择部门"
              style="width: 80%"
            >
              <el-option
                v-for="item in deptoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年份">
            <el-select
              filterable
              clearable
              v-model="searchForm.s_year"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in s_years"
                :key="'year_' + index"
                :value="item"
                :label="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="start_seach"
              style="background: #519f6d"
              >查询</el-button
            >
            <el-button @click="drawer = false">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <div style="clear: both"></div>

    <mFooter></mFooter>
  </div>
</template>

<script>
import { Loading, TabPane } from "element-ui";
import mFooter from "@/components/mFooter.vue";
//官网动态
import {
  ListNews,
  ListNewsFromGw,
  getNewNews,
} from "@/api/content/xtbg_news.js";

//项目资讯
import { ListScienceinfo } from "@/api/content/xtbg_science_info.js";
//内网公告
import { ListNotice } from "@/api/content/xtbg_notice.js";
//园务公开
import { ListReponseopenInfo } from "@/api/content/xtbg_reponse_open.js";
//规章制度
import { ListRulesismInfo } from "@/api/content/xtbg_rules_ism.js";

//部门列表
import { ListDepts, getDeptNameById } from "@/api/system/sys_dept";

// 综合服务类型
import { ListPlatTypes } from "@/api/system/dictdata";
import { ListPlatformService } from "@/api/content/xtbg_platform_service";

//内网新闻
import { ListInNews } from "@/api/content/xtbg_innews.js";

import {
  removeHtml,
  regManyJsonStrToJsonArr,
  sortJsonArr,
  goToTop,
  getYearMonthDay,
  formatDateTime,
  uniqueJsonArrByField,
} from "@/utils/common.js";
// 2024-04-14 新改进
import { from } from "rxjs";
import { map, repeat } from "rxjs/operators";
import axios from "axios";
import CryptoJS from "crypto-js";

var _this = this;
export default {
  inject: ["reload"],
  components: {
    mFooter,
  },
  data() {
    return {
      showGzdept: true,
      radioDept: "",
      radioDeptId: "",
      showDeptGrounp: false,
      showDeptRadio: false,
      showSearchButton: true,
      drawer: false,
      deptoptions: [],
      searchForm: {
        s_title: "",
        s_content: "",
        s_author: "",
        s_dept: "",
        s_year: "",
      },

      showOrFalse: true,
      showContentOrFalse: false,
      showWlNTrurOrFalse: false, //内网新闻、官网动态
      showWlTrurOrFalse: false, //项目资讯、内网公告、园务公开、规章制度

      plat_types: [],
      // fits: ["fill", "fill", "fill", "fill", "fill"],
      // url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",

      total: 0,
      pageSize: 10,
      current_title: "官网动态",
      current_page: 1,
      current_addr: "",
      item_bg_image: require("@/assets/imgs/news/right.png"),
      item_active_bg_image: require("@/assets/imgs/news/right1.png"),

      list_items: [
        {
          name: "反腐倡廉",
          url: "",
        },
        {
          name: "综合服务",
          url: "",
        },

        {
          name: "项目资讯",
          url: "",
        },
        {
          name: "内网新闻",
          url: "",
        },
        {
          name: "内网公告",
          url: "",
        },
        {
          name: "官网动态",
          url: "",
        },
        {
          name: "园务公开",
          url: "",
        },
        {
          name: "规章制度",
          url: "",
        },
      ],
      list_content_items: [],
      list_data: [],
      dept: "",
      bm_list: [],
      choosedate: "",
      gwdt_data: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      serachtext: "",
      queryPramas: {
        pageSize: 10,
        pageNum: 1,
      },
      loadingInstance1: null,
      wjhidden: true,

      searchByWhere: false,
      searchResultList: [],
      s_years: [],
      s_author_show: false,

      ruleIsmDepts: [],
      dynamicBackgroundImage: require("../../assets/imgs/news/jtdown.png"),
    };
  },
  computed: {
    dynamicStyles() {
      return {
        backgroundImage: this.dynamicBackgroundImage,
        backgroundSize: "cover", // 或者其他CSS背景属性
      };
    },
    getContentStyle() {
      if (!this.showContentOrFalse) {
        return {
          display: "flex",
          flexDirection: "column",
          width: this.showGzdept ? "100%" : "calc(100% - 180px)",
        };
      }
    },
  },
  async created() {
    //模拟数据
    _this = this;
    this.getListDepts();
    this.setSyears();
    await this.getDeptInfo();
    await this.getRuleIsmDept();

    //获取数据
    //console.log(this.fetchListData("xtbg_notice"));
  },

  watch: {
    "$store.state.loginwj": {
      handler(newV) {
        this.wjhidden = newV;
      },
      deep: true,
    },
    $route: function (to, from) {
      

      if (
        this.$route.query.name == "官网动态" ||
        this.$route.query.name == "综合服务"
      ) {
        this.showSearchButton = false;
        this.showDeptGrounp = false;
      } else {
        this.showSearchButton = true;
      }
      if (this.$route.query.name == "内网新闻") {
        this.s_author_show = true;
      } else {
        this.s_author_show = false;
      }

      if (this.$route.query.name == "规章制度") {
        this.showDeptGrounp = true;

        this.showGzdept = false;
      } else {
        this.showDeptGrounp = false;
        this.showDeptRadio = false;
        this.showGzdept = true;
      }

      this.$nextTick(function () {
        this.selectItem(this.$route.query.name);
        this.initeData(this.$route.query.name);
      });
    },
  },
  methods: {
    encrypt(text) {
      const ENCRYPTION_KEY = "12345678901234567890123456789012"; // 必须是32字节
      const IV_LENGTH = 16; // 初始化向量的长度

      // 生成随机的初始化向量（IV）
      const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);

      // 使用 AES 加密
      const encrypted = CryptoJS.AES.encrypt(
        text,
        CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // 返回加密后的字符串和 IV
      return (
        iv.toString(CryptoJS.enc.Hex) +
        ":" +
        encrypted.ciphertext.toString(CryptoJS.enc.Hex)
      );
    },
    showBoxDept() {
      this.$refs.boxDept.classList.remove("hidden");
      this.$refs.boxDept.classList.add("active");
    },
    hideBoxDept() {
      this.$refs.boxDept.classList.remove("active");
      this.$refs.boxDept.classList.add("hidden");
    },
    showDeptList() {
      if (this.showDeptRadio) {
        this.showDeptRadio = false;
        this.hideBoxDept();
      } else {
        this.showDeptRadio = true;
        this.showBoxDept();
      }
    },
    async getRuleIsmDept() {
      const sqlStr =
        "SELECT DISTINCT xtbg_rules_ism.dept_id, sys_dept.dept_name FROM xtbg_rules_ism JOIN sys_dept ON xtbg_rules_ism.dept_id = sys_dept.dept_id";
      const deptRes = await this.$dbquery(this.$http, sqlStr);
      this.ruleIsmDepts = deptRes.map((row) => ({
        value: row.dept_id,
        label: row.dept_name,
      }));
    },
    handleRadioChange(value) {
      this.radioDeptId = this.ruleIsmDepts.find(
        (option) => option.label === value
      )?.value;

      this.searchForm.s_dept = this.radioDeptId;
      this.searchByWhere = true;

      this.start_seach_bycondition("xtbg_rules_ism");
      //console.log("选中的值:", value,this.radioDeptId);
      // 在这里执行你的其他操作
    },

    async fetchListData(tableName) {
      // 并行获取分页数据和总数
      const [pageData, total] = await Promise.all([
        this.getPageData(tableName),
        this.getTableTotal(tableName),
      ]);

      // 处理数据
      const formattedData = pageData.map((row) => ({
        id: row.id,
        title: row.title,
        deptId: row.dept_id,
        createTime: getYearMonthDay(row.create_time),
      }));

      return {
        data: {
          rows: formattedData,
          total: total,
        },
      };
    },

    async getPageData(tableName) {
      const offset = (this.queryPramas.pageNum - 1) * this.queryPramas.pageSize;
      const limit = this.queryPramas.pageSize;

      // 构建查询语句并进行分页和排序
      let selectStr = `SELECT id, title, dept_id, create_time FROM ${tableName}`;
      if (tableName === "xtbg_notice") {
        selectStr += " WHERE end_time IS NULL OR end_time >= CURRENT_DATE";
      }
      selectStr += ` ORDER BY create_time DESC LIMIT ${limit} OFFSET ${offset}`;

      try {
        const pageDataRes = await this.$dbquery(this.$http, selectStr);
        return pageDataRes;
      } catch (error) {
        console.error("Error fetching page data:", error);
        return [];
      }
    },

    async getTableTotal(tableName) {
      // 构建总数查询语句
      let totalSql = `SELECT count(*) as total FROM ${tableName}`;
      if (tableName === "xtbg_notice") {
        totalSql += " WHERE end_time IS NULL OR end_time >= CURRENT_DATE";
      }

      try {
        const queryStr = { query: this.encrypt(totalSql) };

        const totalRes = await this.$http.post(this.$db_dynamic_url, queryStr,{
          headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",
        },
        });

        return totalRes.data[0].total;
      } catch (error) {
        console.error("Error fetching table total:", error);
        return 0;
      }
    },

    
    //获取 部门列表
    async getDeptInfo() {
      const deptSql = "select dept_id,dept_name FROM sys_dept";
      const DeptRes = await this.$dbquery(this.$http, deptSql);
      this.deptoptions = DeptRes.map((row) => ({
        value: row.dept_id,
        label: row.dept_name,
      }));
    },
    //获取年份
    setSyears() {
      let n_year = new Date().getFullYear();
      for (let i = n_year; i > n_year - 11; i--) {
        this.s_years.push(i);
      }
    },
    showSeachBox() {
      this.queryPramas = {
        pageSize: 10,
        pageNum: 1,
      };
      this.drawer = true;
    },
    //根据条件查询
    async start_seach_bycondition(table_name) {
      let whereCondition = "";

      let selectedLabel = this.deptoptions.find(
        (option) => option.value === this.searchForm.s_dept
      )?.label;

      if (table_name == "xtbg_innews") {
        if (selectedLabel) {
          whereCondition =
            "title like '%" +
            this.searchForm.s_title +
            "%' and content like '%" +
            this.searchForm.s_content +
            "%' and dept = '" +
            selectedLabel +
            "' and create_by like '%" +
            this.searchForm.s_author +
            "%' and create_time like '%" +
            this.searchForm.s_year +
            "%'";
        } else {
          whereCondition =
            "title like '%" +
            this.searchForm.s_title +
            "%' and content like '%" +
            this.searchForm.s_content +
            "%' and create_by like '%" +
            this.searchForm.s_author +
            "%' and create_time like '%" +
            this.searchForm.s_year +
            "%'";
        }
      } else {
        if (this.searchForm.s_dept) {
          whereCondition =
            "title like '%" +
            this.searchForm.s_title +
            "%' and content like '%" +
            this.searchForm.s_content +
            "%' and dept_id = '" +
            this.searchForm.s_dept +
            "' and create_by like '%" +
            this.searchForm.s_author +
            "%' and create_time like '%" +
            this.searchForm.s_year +
            "%'";
        } else {
          whereCondition =
            "title like '%" +
            this.searchForm.s_title +
            "%' and content like '%" +
            this.searchForm.s_content +
            "%' and create_by like '%" +
            this.searchForm.s_author +
            "%' and create_time like '%" +
            this.searchForm.s_year +
            "%'";
        }
      }

      let pagewhere =
        whereCondition +
        "ORDER BY create_time DESC LIMIT " +
        this.queryPramas.pageSize +
        " OFFSET " +
        (this.queryPramas.pageNum - 1) * this.queryPramas.pageSize;
      let querySqlStr = "select * from " + table_name + " where " + pagewhere;

      const loading = this.$loading({
        lock: true,
        text: "正在查询...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
     // console.log(querySqlStr);
      const queryRes = await this.$dbquery(this.$http, querySqlStr);

      const totalRes = await this.$dbquery(
        this.$http,
        "select count(*) as total from " +
          table_name +
          " where " +
          whereCondition
      );

      setTimeout(() => {
        loading.close();
      }, 1000);

      let queryResult = queryRes.map((row) => ({
        ...row,

        deptId: row.dept_id,
        createTime: getYearMonthDay(row.create_time),
      }));


      let new_res = {
        data: {
          total: totalRes[0].total,
          rows: queryResult,
        },
      };

      console.log("查询结果", queryResult);

      this.converData(new_res);
    },
    // 开始查询
    start_seach() {
      this.searchByWhere = true;

      this.showContentOrFalse = false;
      this.showWlTrurOrFalse = false;
      this.showWlNTrurOrFalse = false;
      this.showOrFalse = true;

      switch (this.$route.query.name) {
        case "反腐倡廉":  
          this.showWlTrurOrFalse = true;
          this.s_author_show = false;
          this.start_seach_bycondition("xtbg_ati_graft");

          break;
        case "项目资讯":
          this.showWlTrurOrFalse = true;
          this.s_author_show = false;
          //this.queryPramas.title = this.searchForm.s_title;

          this.start_seach_bycondition("xtbg_science_info");

          break;
        case "内网新闻":
          this.showWlNTrurOrFalse = true;
          this.start_seach_bycondition("xtbg_innews");
          break;
        case "内网公告":
          this.s_author_show = false;
          this.showWlTrurOrFalse = true;
          this.start_seach_bycondition("xtbg_notice");
          break;
        case "官网动态":
          break;
        case "园务公开":
          this.showWlTrurOrFalse = true;
          this.s_author_show = false;
          this.start_seach_bycondition("xtbg_reponse_open");
          break;
        case "规章制度":
          this.showWlTrurOrFalse = true;
          this.s_author_show = false;
          this.start_seach_bycondition("xtbg_rules_ism");
          break;
      }
      // setTimeout(() => {
      //   _this.loadingInstance1.close();
      //   // this.drawer = false;
      // }, 500);
    },
    //部门列表
    getListDepts() {
      this.bm_list = [];
      ListDepts().then((res) => {
        res.data.data.map((row) => {
          this.bm_list.push({
            value: row.deptName,
          });
        });
      });
    },
    btn_search() {
      this.$message({
        message: "正在努力开发中，请稍后...",
        type: "warning",
        offset: 200,
      });
    },
    querySearchBm(queryString, cb) {
      var restaurants = this.bm_list;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    handleSelectBm() {},
    ListPlatServices(typename) {
      let queryParams = {
        pageSize: 100,
        pageNum: 1,
        typename: typename,
      };
      ListPlatformService(queryParams).then((res) => {});
    },
    //排序
    up(x, y) {
      return x.orderNum - y.orderNum;
    },
    goTo(url) {
      if (url == "/") {
        this.$message({
          message: "正在开发中...",
          type: "info",
          offset: 200,
          center: true,
        });
        return false;
      }
      if (url.indexOf("http") > -1) {
        window.open(url);
      } else {
        //window.open(url);
        this.$router.push({
          path: url,
          target: "_blank",
        });
      }
    },
    //初始化 数据
    async initeData(navName) {
      this.showContentOrFalse = false;
      this.showWlTrurOrFalse = false;
      this.showWlNTrurOrFalse = false;
      this.showOrFalse = true;
      this.loadingInstance1 = Loading.service({
        fullscreen: true,
        text: "正在加载...",
      });
      this.list_data = [];
      this.plat_types = [];

      switch (navName) {
        case "反腐倡廉":
          this.showWlTrurOrFalse = true;
          // const ScienceRes = await ListScienceinfo(this.queryPramas);
          const res_ati_graft = await this.fetchListData("xtbg_ati_graft");
          this.converData(res_ati_graft);
          break;
        case "综合服务":
          this.showContentOrFalse = true;
          this.showOrFalse = false;
          this.loadingInstance1.close();
          if (this.plat_types.length > 0) {
            this.plat_types = [];
          }
          let queryParams = {
            pageSize: 100,
            pageNum: 1,
          };
          let typename_arr = [];
          ListPlatformService(queryParams).then((response) => {
            var rows = response.data.rows.sort(this.up);
            rows.map((row) => {
              typename_arr.push(row.typename);
            });
            typename_arr = [...new Set(typename_arr)];
            for (let i = 0; i < typename_arr.length; i++) {
              let typename = typename_arr[i];

              let platServices2 = [];
              for (let j = 0; j < rows.length; j++) {
                if (typename == rows[j].typename) {
                  rows[j].thumbnail = this.$api_url + rows[j].thumbnail;
                  platServices2.push(rows[j]);
                }
              }

              this.plat_types.push({
                typename: typename,
                platServices: platServices2.sort(this.up),
              });

              //去重
              this.plat_types = uniqueJsonArrByField(
                this.plat_types,
                "typename"
              );
            }
          });

          break;
        case "官网动态":
          // ListNews(this.queryPramas).then((res) => {
          //   this.converData(res);

          // });
          this.showWlNTrurOrFalse = true;
          this.list_data = [];
          this.gwdt_data = [];
          this.pageSize = 10;
          getNewNews().then((result) => {
            regManyJsonStrToJsonArr(result).map((row) => {
              row.abstract = row.abstract ? row.abstract.substring(0, 100) : "";
              let newRow = {
                thumbnail: row.image,
                createTime: row.date,
                deptName: row.dept,
                content: row.abstract,
                title: row.title,
                link: row.link,
              };
              this.gwdt_data.push(newRow);
            });

            this.total = this.gwdt_data.length;
            this.list_data = this.gwdt_data.slice(0, this.pageSize);
          });
          this.loadingInstance1.close();

          break;
        case "内网公告":
          this.showWlTrurOrFalse = true;
          //const noticdRes = await ListNotice(this.queryPramas);

          let noticdRes = await this.fetchListData("xtbg_notice");

          this.converData(noticdRes);
          break;
        case "项目资讯":
          this.showWlTrurOrFalse = true;
          // const ScienceRes = await ListScienceinfo(this.queryPramas);
          const ScienceRes = await this.fetchListData("xtbg_science_info");
          this.converData(ScienceRes);
          break;
        case "内网新闻":
          this.showWlNTrurOrFalse = true;
          const inNewsRes = await ListInNews(this.queryPramas);
          this.converData(inNewsRes);
          break;
        // case "合同公示":
        //   this.showWlTrurOrFalse = true;
        //   ListWorkprogress(this.queryPramas).then((res) => {
        //     this.converData(res);
        //   });
        //   break;
        case "规章制度":
          this.showWlTrurOrFalse = true;
          //const RulesIsmRes = await ListRulesismInfo(this.queryPramas);
          const RulesIsmRes = await this.fetchListData("xtbg_rules_ism");
          this.converData(RulesIsmRes);

          break;
        case "园务公开":
          this.showWlTrurOrFalse = true;
          const responseRes = await ListReponseopenInfo(this.queryPramas);
          this.converData(responseRes);
          break;
        // case "办事指南":
        // this.showWlTrurOrFalse = true;
        //   ListWorkguideInfo(this.queryPramas).then((res) => {
        //     this.converData(res);
        //   });
        //   break;
        // case "学术报告":
        // this.showWlTrurOrFalse = true;
        //   ListAcademicreport(this.queryPramas).then((res) => {
        //     this.converData(res);
        //   });
        //   break;
      }

      setTimeout(() => {
        _this.loadingInstance1.close();
      }, 500);
    },

    async converData(res) {
      let rows = res.data.rows;
      this.total = res.data.total;
      this.showOrFalse = this.total > 10;
      this.list_data = [];

      // 创建部门ID到名称的映射，减少重复查询
      const deptIdMap = {};

      // 处理每一行数据
      for (const row of rows) {
        if (row.content != null) {
          row.content = removeHtml(row.content);
          if (row.content.length > 100) {
            row.content = row.content.substring(0, 100) + "...";
          }
        }

        if (row.thumbnail != null && !row.thumbnail.startsWith("http")) {
          row.thumbnail = this.$api_url + row.thumbnail;
        }

        if (row.deptId !== undefined) {
          if (!deptIdMap[row.deptId]) {
            try {
              const deptRes = await this.$getDeptNameByDeptId(
                this.$http,
                row.deptId
              );
              deptIdMap[row.deptId] = deptRes[0]?.dept_name || "Unknown";
            } catch (error) {
              console.error("Error fetching department name:", error);
              deptIdMap[row.deptId] = "Error";
            }
          }
          row.deptName = deptIdMap[row.deptId];
        } else if (row.dept !== undefined && row.dept !== "undefined") {
          row.deptName = row.dept;
        }

        this.list_data.push(row);
      }

      // 去除重复的标题
      this.list_data = this.$_.uniqBy(this.list_data, "title");

      // 延迟更新DOM，处理图片加载错误
      setTimeout(() => {
        const el_image_errors =
          document.getElementsByClassName("el-image__error");
        Array.from(el_image_errors).forEach((errorElement) => {
          errorElement.innerHTML =
            '<div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>';
        });
      }, 100);
    },
    // 格式化 所有数据
    // async converData(res) {
    //   let rows = res.data.rows;
    //   this.total = res.data.total;
    //   this.showOrFalse = this.total > 10;
    //   this.list_data = [];

    //   for (const row of rows) {
    //     if (row.content != null) {
    //       row.content = removeHtml(row.content);
    //       if (row.content && row.content.length > 100) {
    //         row.content = row.content.substring(0, 100) + "...";
    //       }
    //     }

    //     if (row.thumbnail != null && row.thumbnail.indexOf("http") == -1) {
    //       row.thumbnail = _this.$api_url + row.thumbnail;
    //     }

    //     //row.createTime = getYearMonthDay(row.createTime);

    //     if (row.deptId !== undefined) {
    //       try {
    //         const res = await this.$getDeptNameByDeptId(this.$http,row.deptId);
    //         row.deptName = res[0].dept_name;
    //       } catch (error) {
    //         console.error("Error fetching department name:", error);
    //       }
    //     } else if (row.dept !== undefined && row.dept !== "undefined") {
    //       row.deptName = row.dept;
    //     }

    //     this.list_data.push(row);

    //     this.list_data = this.$_.uniqBy(this.list_data,"title");
    //   }

    //   setTimeout(() => {
    //     const el_image_errors =
    //       document.getElementsByClassName("el-image__error");
    //     Array.from(el_image_errors).forEach((errorElement) => {
    //       errorElement.innerHTML =
    //         '<div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>';
    //     });
    //   }, 100);
    // },
    isInJson(jsonArr, title) {
      jsonArr.map((item) => {
        if (JSON.stringify(item).indexOf(title) > -1) {
          return true;
        }
      });
      return false;
    },
    reduceArr(arr) {
      let hash = {};
      arr = arr.reduce(function (item, next) {
        hash[next.title] ? "" : (hash[next.title] = true && item.push(next));
        return item;
      }, []);
      return arr;
    },

    // 翻页 操作
    currentChange(cpage) {
      // this.current_page = cpage;
      // this.list_content_items = [];

      if (this.gwdt_data.length > 0) {
        let startIndex = (cpage - 1) * this.pageSize;
        let endIndex = cpage * this.pageSize;

        this.list_data = this.gwdt_data.slice(startIndex, endIndex);
      } else {
        this.queryPramas.pageNum = cpage;
        if (this.searchByWhere) {
          console.log("查询分页", this.queryPramas.pageNum);
          this.start_seach();
        } else {
          this.initeData(this.current_title);
        }
      }
      goToTop();
    },
    clickListItem(e, name) {
      this.queryPramas = {
        pageNum: 1,
        pageSize: 10,
      };
      this.current_title = name;
      if (name == "官网动态") {
        this.pageSize = 50;
      } else {
        this.pageSize = 10;
      }
      //修改路由
      this.$router.push({
        path: "/news",
        query: {
          name: name,
        },
      });

      this.current_addr =
        "<a href='/' style='cursor:point;text-decoration:none'>首页</a>>" +
        "<a href='/news?name=" +
        name +
        "' style='cursor:point;text-decoration:none'>" +
        name +
        "</a>";
      this.activeEl(e);
      this.initeData(name);
    },
    activeEl(e) {
      let parentEL = e.currentTarget.parentElement;
      let lis = parentEL.querySelectorAll("li");
      for (let i = 0; i < lis.length; i++) {
        let mli = lis[i];
        mli.style.background = "url('" + this.item_bg_image + "') no-repeat";
        mli.style.backgroundPosition = "right center";
        mli.style.backgroundPositionX = "250px";
        mli.style.backgroundSize = "16px 16px";
        mli.style.color = "#000";
      }
      let current_li = e.currentTarget;
      current_li.style.background =
        "url('" + this.item_active_bg_image + "') no-repeat #519f6d";
      current_li.style.backgroundPosition = "right center";
      current_li.style.backgroundPositionX = "250px";
      current_li.style.backgroundSize = "16px 16px";
      current_li.style.color = "#fff";
    },
    selectItem(name) {
      let mlis = document
        .getElementsByClassName("list_news-items")[0]
        .querySelectorAll("li");
      for (let i = 0; i < mlis.length; i++) {
        let current_li = mlis[i];
        this.current_title = name;
        this.current_addr =
          "<a href='/' style='cursor:point;text-decoration:none'>首页</a>>" +
          "<a href='/news?name=" +
          name +
          "' style='cursor:point;text-decoration:none'>" +
          name +
          "</a>";

        current_li.style.background =
          "url('" + this.item_bg_image + "') no-repeat";

        current_li.style.backgroundPosition = "right center";
        current_li.style.backgroundPositionX = "250px";
        current_li.style.backgroundSize = "16px 16px";
        current_li.style.color = "#000";

        if (current_li.innerText == name) {
          this.current_title = name;
          this.current_addr =
            "<a href='/' style='cursor:point;text-decoration:none'>首页</a>>" +
            "<a href='/news?name=" +
            name +
            "' style='cursor:point;text-decoration:none'>" +
            name +
            "</a>";

          current_li.style.background =
            "url('" + this.item_active_bg_image + "') no-repeat #519f6d";

          current_li.style.backgroundPosition = "right center";
          current_li.style.backgroundPositionX = "250px";
          current_li.style.backgroundSize = "16px 16px";
          current_li.style.color = "#fff";
        }
      }
    },
    getRnd(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    showDetail(item) {
      this.$store.commit("updateSelectedTitle", this.current_title);

      localStorage.setItem("title", encodeURI(this.current_title));
      localStorage.setItem("showTitle", encodeURI(item.title));
      localStorage.setItem("deptName", item.deptName);
      if (item.link != "undefined" && this.current_title == "官网动态") {
        window.open(item.link);
      } else {
        this.$router.push({
          path: "/newsdetail",
          query: {
            // title: encodeURI(this.current_title),
            // showTitle: encodeURI(item.title),
            id: item.id,
          },
        });
      }
    },
  },
  mounted() {
    //console.log(this.$route.query.name);
    //window.location.reload();

    this.selectItem(this.$route.query.name);
    this.initeData(this.$route.query.name);
    if (this.$route.query.name == "综合服务") {
      this.showOrFalse = false;
      this.showContentOrFalse = true;
    } else {
      this.showOrFalse = true;
      this.showContentOrFalse = false;
    }

    let roleName = localStorage.getItem("roleName");
    //判断权限，如果是外籍 用户 则隐藏有些模块
    if (roleName != null) {
      if (roleName.indexOf("外籍") > -1) {
        this.wjhidden = false;
        this.list_items = this.list_items.filter((row) => {
          return row.name.indexOf("通知") == -1;
        });
        this.list_items = this.list_items.filter((row) => {
          return row.name.indexOf("合同公示") == -1;
        });
        this.list_items = this.list_items.filter((row) => {
          return row.name.indexOf("内网新闻") == -1;
        });
      } else {
        this.wjhidden = true;
      }
    }
    if (this.$route.query.name == "规章制度") {
      this.showDeptGrounp = true;
      this.showGzdept = false;
    } else {
      this.showDeptGrounp = false;
      this.showDeptRadio = false;
      this.showGzdept = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.boxDept.active {
  transform: translateY(0);
}

.boxDept.hidden {
  transform: translateY(-100%);
}
.radiogroupdept {
  margin-top: 10;

  width: 40px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: calc((100% - 1400px) / 2);
  z-index: 999;
  cursor: pointer;
  // border: 1px solid #4c4c4c;
  font-size: 12px;
  padding: 0px 2px;
  color: #fafafa;
  background: rgb(81, 159, 109);
}
.bny_news-list {
  padding: 0px 0px 20px 0px;
  width: 1400px;
  height: auto;
  margin: 0px auto;
  /* background: #f2f2f2; */
  .bny_news-list-left {
    width: 298px;
    float: left;
    height: auto;
    background-color: #fff;
    border: 1px solid #f2f2f2;

    .bny_news-list-left-top {
      height: 120px;
      line-height: 120px;
      width: 100%;
      background: url("@/assets/imgs/news/list-top.jpg") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      border-bottom: 1px solid #f2f2f2;
    }
    .bny_news-list-left-items {
      height: auto;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: block;
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 16px;
          color: #4c4c4c;
          font-weight: bold;
          border-bottom: 1px solid #f2f2f2;
          background: url("@/assets/imgs/news/right.png") no-repeat;
          background-position: right center;
          background-position-x: 250px;
          background-size: 16px 16px;
          &:hover {
            cursor: pointer;
            background: url("@/assets/imgs/news/right1.png") no-repeat #519f6d;
            background-position: right center;
            background-position-x: 250px;
            background-size: 16px 16px;
            color: #fff;
          }
        }
      }
    }
  }

  .bny_news-list-right {
    width: 1080px;
    float: left;
    height: auto;
    margin-left: 20px;
    background-color: #fff;

    .bny_news-list-right-top {
      height: 60px;
      line-height: 60px;
      color: #000;
      background: #f3f4f9;
      width: calc(100% - 5px);
      text-align: left;
      padding-left: 5px;
      font-size: 14px;
      span {
        color: #519f6d;
      }
      box-shadow: 0px 0px 4px 1px rgba($color: #000000, $alpha: 0.1);
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 14px;
        li {
          float: left;
          margin: 0px;
          width: 250px;
        }
      }
    }
    .bny_news-list-searchbar {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #ddd;
      .el-input-group__append,
      .el-input-group__prepend {
        padding: 0 8px !important;
      }
    }
    .bny_news-list-right-content {
      margin-top: 5px;
      height: auto;
      padding-bottom: 20px;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          .xmzx-list-item {
            height: 62px;
            line-height: 62px;
            border-bottom: 1px dashed #e8e8e8;

            &:hover {
              color: #519f6d;
              border-bottom: 1px dotted #519f6d;
              cursor: pointer;
              font-weight: 600;
              padding-left: 23px;
              transition: all 0.5s;
            }
            padding-left: 20px;
          }
          .nwxw-list-item {
            height: 180px;
            width: 100%;
            //box-shadow: 1px 1px 4px 3px rgba($color: #000000, $alpha: 0.1);
            margin: 18px 0px;
            border: 1px solid #e8e8e8;
            border-bottom: 4px solid #eaeaea;

            &:hover {
              //box-shadow: -2px -2px 4px 3px rgba($color: #000000, $alpha: 0.1);
              margin: 18px 0px;
              cursor: pointer;
              border-bottom: 4px solid #047632;
            }
            .bny_news-list-right-content-left {
              float: left;
              height: 150px;
              width: 280px;
              padding: 15px;
              img {
                height: 148px;
                width: 148px;
                border: 1px solid #f2f2f2;
                box-shadow: 1px 1px 4px 3px rgba($color: #000000, $alpha: 0.1);
                &:hover {
                  box-shadow: -2px -2px 4px 3px rgba($color: #000000, $alpha:
                        0.1);
                }
              }
            }
            .bny_news-list-right-content-right {
              padding: 2px 10px;
              float: left;
              height: 120px;
              width: 740px;
              font-size: 14px;
              div:first-child {
                text-align: left;
                height: 40px;
                line-height: 40px;
                font-weight: bold;
                color: #4c4c4c;
                &:hover {
                  color: #047632;
                }
              }

              div:nth-child(2) {
                text-align: left;
                color: #999999;
              }
              div:last-child {
                text-align: left;
                color: #948b8b;
                padding: 5px 0 0 0;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .el-pagination {
    width: 42%;
    margin-top: 30px;
    margin-left: 403px;
    ::v-deep .btn-prev,
    ::v-deep .btn-next {
      border: 1px solid #f2f2f2;
      background-color: transparent;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    ::v-deep .el-icon-arrow-left:before,
    ::v-deep .el-icon-arrow-right:before {
      color: #d5bfa1;
      font-size: 14px;
    }
    ::v-deep .el-pagination__total {
      width: 40px;
      height: 40px;
      line-height: 37px;
      font-size: 14px;
      margin-right: 40px;
    }
  }

  ::v-deep .el-pagination.is-background .el-pager li {
    border: 1px solid #f2f2f2;
    background-color: #fff;
    color: #000;
    width: 40px;
    height: 40px;
    line-height: 37px;
    font-size: 14px;
    margin-right: 15px;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #519f6d;
    color: #fff;
  }
}
.zhfw-list-items {
  .block {
    float: left;
    text-align: center;
    &:hover {
      box-shadow: 5px 5px 5px #f2f2f2;

      color: #519f6d;
    }
    img {
      width: 100px;
      height: 100px;
    }
  }
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #519f6d;
  border-color: #519f6d;
  box-shadow: -1px 0 0 0 #519f6d;
}
::v-deep .el-radio-button--medium .el-radio-button__inner {
  border: 1px solid #ccc;
  border-radius: 0;
  border-bottom: 0px;

  padding: 8px;
  width: 170px;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏超出容器的内容 */
  text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-radius: 0px;
}
::v-deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0px;
  border-bottom: 1px solid #ccc;
}
</style>
