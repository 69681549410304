<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 17:08:36
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-14 11:01:49
 * @FilePath: \app_wb\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="bny-main">
      <div class="bny-main-left">
        <!-- 轮播图 -->
        <Carousel></Carousel>

        <!-- 项目资讯 -->
        <index_list_item_kjxx
          :titlelogo="xsbg_img"
          title="项目资讯"
          :tableData="tableData_kjxxzl"
          style="margin-top: 20px"
          :TableColumns="TableColumns_kjxxzl"
        ></index_list_item_kjxx>
        <!-- 内网新闻 最新新闻 -->
        <index_list_item_tabsVue
          style="margin-top: 20px"
        ></index_list_item_tabsVue>

        <!-- 合同公示 -->
        <index_list_item
          :titlelogo="zygs_img"
          title="合同公示"
          :tableData="tableData_import"
          style="margin-top: 20px"
          :TableColumns="TableColumns_import"
          v-show="false"
        ></index_list_item>
        <!-- 会议日程安排 -->
        <index_list_calendar
          title="会议日程安排"
          style="margin-top: 20px"
          :titlelogo="rcap_img"
        ></index_list_calendar>
        <div style="clear: both"></div>

        <!-- 学术报告 -->
        <index_list_item
          :titlelogo="xsbg_img"
          title="学术报告"
          :tableData="tableData_xsbg"
          :TableColumns="TableColumns_xsbg"
          v-show="false"
          style="margin-top: 20px"
        ></index_list_item>
      </div>
      <div class="bny-main-right">
        <!-- 应用 -->
        <index_list_item_appsVue></index_list_item_appsVue>
        <!-- 搜索 -->
        <!-- <index_list_item_kzssVue></index_list_item_kzssVue> -->
        <!-- 站内搜索 -->
        <index_list_item_ssVue
          v-show="true"
          style="margin-top: 20px"
        ></index_list_item_ssVue>
        <!-- 发展规划 -->
        <index_list_item_fzgh></index_list_item_fzgh>
        <!-- 内网公告 -->
        <index_list_item_nwgg
          v-show="wjhidden"
          :titlelogo="notice_img"
          title="内网公告"
          :tableData="tableData_notice"
          :TableColumns="TableColumns_notice"
          style="margin-top: 20px"
        ></index_list_item_nwgg>
        <!-- 领导日程 -->
        <index_list_item_ldrc
          v-show="wjhidden"
          :titlelogo="lidaoricheng_img"
          title="领导日程"
          style="margin-top: 20px"
        ></index_list_item_ldrc>

        <!-- 到款通知 -->
        <!-- <index_list_item_dktz
          v-show="wjhidden"
          :dklogo="dztz_img"
          style="margin-top: 20px;"
        ></index_list_item_dktz> -->
      </div>
    </div>
    <div style="clear: both"></div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mFooter from "../components/mFooter.vue";
import Carousel from "../components/Index/Carousel.vue";
import index_list_item_kjxx from "@/components/commom/index_list_item kjxx.vue";
import index_list_item from "@/components/commom/index_list_item.vue";
import index_list_item_nwgg from "@/components/commom/index_list_item nwgg.vue";
import index_list_item_ldrc from "@/components/commom/index_list_item_ldrc.vue";

import index_list_item_kzssVue from "@/components/commom/index_list_item_kzss.vue";
import index_list_item_ssVue from "@/components/commom/index_list_item_ss.vue";
import index_list_item_fzgh from "@/components/commom/index_list_item_fzgh.vue";
import index_list_item_dktz from "@/components/commom/index_list_item_dktz.vue";
import index_list_item_appsVue from "@/components/commom/index_list_item_apps.vue";
import index_list_item_tabsVue from "@/components/commom/index_list_item_tabs.vue";
import index_list_calendar from "@/components/commom/index_list_calendar.vue";

//拉取新闻列表
import { ListNotice } from "@/api/content/xtbg_notice.js";
import { getDeptNameById } from "@/api/content/xtbg_common.js";
//科技信息
import { ListScienceinfo } from "@/api/content/xtbg_science_info.js";

//合同公示
import { ListWorkprogress } from "@/api/content/xtbg_work_progress.js";
//学术报告
import { ListAcademicreport } from "@/api/content/xtbg_academic_report.js";
import { getYearMonthDay } from "@/utils/common.js";
var _this = null;

export default {
  components: {
    mFooter,
    Carousel,
    index_list_item,
    index_list_item_ldrc,
    index_list_item_kzssVue,
    index_list_item_dktz,
    index_list_item_appsVue,
    index_list_item_ssVue,
    index_list_item_fzgh,
    index_list_item_tabsVue,
    index_list_calendar,
    index_list_item_nwgg, //内网公告
    index_list_item_kjxx, //科技信息
  },
  data() {
    return {
      notice_img: require("../assets/imgs/list-items/notice.png"),
      // 内网新闻
      work_img: require("../assets/imgs/list-items/work.png"),
      //最新新闻
      news_img: require("../assets/imgs/list-items/news.png"),
      //合同公示
      rcap_img: require("../assets/imgs/list-items/calendar.png"),
      //合同公示
      zygs_img: require("../assets/imgs/list-items/zygs.png"),
      //学术报告
      xsbg_img: require("../assets/imgs/list-items/xmzx.png"),
      //提案
      tian_img: require("../assets/imgs/list-items/tian.png"),
      //咨询平台
      zixun_img: require("../assets/imgs/list-items/zixuntai.png"),
      //领导日程
      lidaoricheng_img: require("../assets/imgs/list-items/richeng.png"),
      //到账通知
      dztz_img: require("../assets/imgs/list-items/money.png"),
      //内网公告
      TableColumns_notice: [
        {
          id: 1,
          label: "标题",
          pname: "title",
          width: "",
        },
        // {
        //   id: 2,
        //   label: "发文日期",
        //   pname: "createTime",
        //   width: "",
        // },
      ],
      tableData_notice: [],
      //项目资讯
      TableColumns_kjxxzl: [
        {
          id: 1,
          label: "标题",
          pname: "title",
          width: "",
        },
        {
          id: 1,
          label: "发布部门",
          pname: "deptName",
          width: "180px",
        },
        {
          id: 2,
          label: "时间",
          pname: "createTime",
          width: "100px",
        },
      ],
      tableData_kjxxzl: [],

      //合同公示
      TableColumns_import: [
        {
          id: 1,
          label: "标题",
          pname: "title",
          width: "600px",
        },
        {
          id: 2,
          label: "发文日期",
          pname: "createTime",
          width: "200px",
        },
        {
          id: 3,
          label: "发文部门",
          pname: "deptName",
          width: "200px",
        },
      ],
      tableData_import: [],
      //学术报告
      TableColumns_xsbg: [
        {
          id: 1,
          label: "标题",
          pname: "title",
          width: "800px",
        },
        {
          id: 2,
          label: "报告日期",
          pname: "createTime",
          width: "200px",
        },
      ],

      tableData_xsbg: [
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
        },
      ],

      queryPramas: {
        pageSize: 10,
        pageNum: 1,
      },
      wjhidden: false,
    };
  },
  created() {
    _this = this;
    this.geListNotice(); //内网公告
    this.getListScienceinfo(); //科技信息
    this.getListWorkprogress(); //合同公示
    this.getListAcademicreport(); //学术报告
  },

  methods: {
    //根据部门ID获取部门名称
    getDeptNameById(id) {
      return new Promise((resolve) => {
        this.$getDeptNameByDeptId(this.$http,id).then((res) => {
          resolve(res[0].dept_name);
        });
      });
    },
    //内网公告
    geListNotice() {
      this.tableData_notice = [];
      let mqueryPramas = {
        pageSize: 10,
        pageNum: 1,
      };
      ListNotice(mqueryPramas).then((res) => {
        let rows = res.data.rows;

        rows.map((row) => {
          row.createTime = getYearMonthDay(row.createTime);
          row.title =
            row.title +
            "  [<span style='color:#409EFF;font-size:12px;'>" +
            row.createTime +
            "</span>]";
          _this.$nextTick(function () {
            _this.tableData_notice.push(row);
          });
        });
      });
    },
    //科技信息
    async getListScienceinfo() {
      this.tableData_kjxxzl = [];
      this.queryPramas.pageSize = 8;
      const res = await ListScienceinfo(this.queryPramas);
      //console.log(res.data.rows);
      await Promise.all(
        res.data.rows.map(async (row) => {
          row.createTime = getYearMonthDay(row.createTime);

          if (row.deptId) {
            row.deptName = (await _this.$getDeptNameByDeptId(this.$http,row.deptId))[0].dept_name;
          }
          row.createTime = getYearMonthDay(row.createTime);
          //console.log(row);
          this.tableData_kjxxzl.push(row);
        })
      );
    },
    //合同公示 ListWorkprogress
    getListWorkprogress() {
      ListWorkprogress(this.queryPramas).then((res) => {
        this.tableData_import = [];

        let rows = res.data.rows;

        rows.map((row) => {
          (function (deptId) {
            if (deptId != null) {
              _this.$getDeptNameByDeptId(_this.$http,deptId).then((res) => {
                row.deptName = res[0].dept_name;
                _this.$nextTick(function () {
                  _this.tableData_import.push(row);
                });
              });
            } else {
              _this.tableData_import.push(row);
            }
          })(row.deptId);
        });
      });
    },
    //学术报告
    getListAcademicreport() {
      ListAcademicreport(this.queryPramas).then((res) => {
        this.tableData_xsbg = res.data.rows;
      });
    },
    showNotice(message) {
      this.$notify({
        title: "【消息通知】-提案系统",
        dangerouslyUseHTMLString: true,
        message: message,
        position: "bootom-right",
        duration: 0,
        offset: 20,
      });
    },
    // localStorage.getItem("userid") , config 文件this.$congresConfig.congressRole.roleid_taz
    async noticeByRole(userid, roleId) {
      let roleRes = await this.$dbquery(
        this.$http,
        "SELECT role_id FROM xtbg_congress_role_user WHERE user_id=" + userid
      );

      //提案组 和 园领导
      if (roleRes[0]) {
        const mroleId = roleRes[0].role_id;

        //console.log("当前角色", this.$congresConfig.congressRole.roleid_taz);
        let operate = "";
        let status = "";
        switch (mroleId) {
          case this.$congresConfig.congressRole.roleid_taz:
            operate = "审查";
            status = "待审查";
            break;
          case this.$congresConfig.congressRole.roleid_yld:
            operate = "审批";
            status = "待审批";
            break;
          case this.$congresConfig.congressRole.roleid_cbbm:
            operate = "处理";
            status = "处理中";
            break;
        }

        //登录的 是属于提案组的
        //<div style='height:100px;'><br/><a href='/proposal_meetting' style='font-size:16px'>提案系统</a></div>
        //去查是否有需要待审查的 提案

        const dclRes = await this.$dbquery(
          this.$http,
          "select num from xtbg_congress_propose where status='" + status + "'"
        );
        if (dclRes.length > 0) {
          if (status == "待审批") {
            //领导 分管的部

            const sSqlStr =
              "SELECT num FROM xtbg_congress_propose WHERE `status` = '待审批' AND suggest_dept in (SELECT dept_name " +
              "FROM sys_dept " +
              "WHERE dept_id IN (" +
              " SELECT dept_id " +
              " FROM xtbg_congress_leader_dept " +
              "WHERE user_id = " +
              localStorage.getItem("userid") +
              ") " +
              "AND dept_name IN (" +
              "SELECT suggest_dept " +
              "FROM xtbg_congress_propose " +
              "WHERE `status` = '待审批' AND suggest_dept != ''" +
              "))";
            const leaderRes = await this.$dbquery(this.$http, sSqlStr);
            if (leaderRes.length > 0) {
              this.showNotice(
                "<div style='height:100px;'><br/>您有<span style='font-size:16px;color:red;'>" +
                  leaderRes.length +
                  "</span>个提案需要" +
                  operate +
                  "，编号为：[<span style='font-size:14px; color:#303133'>" +
                  leaderRes.map((row) => row.num).join(",") +
                  "</span>]，点击前往[提案系统-当前提案]<a href='/proposal_meetting' style='font-size:16px;color:red'>" +
                  operate +
                  "</a></div>"
              );
            }
          } else {
            const dcls = dclRes.map((row) => row.num).join(",");
            if (dclRes.length > 0) {
              this.showNotice(
                "<div style='height:100px;'><br/>您有<span style='font-size:16px;color:red;'>" +
                  dclRes.length +
                  "</span>个提案需要" +
                  operate +
                  "，编号为：[<span style='font-size:14px; color:#303133'>" +
                  dcls +
                  "</span>]，点击前往[提案系统-当前提案]<a href='/proposal_meetting' style='font-size:16px;color:red'>" +
                  operate +
                  "</a></div>"
              );
            }
          }
        }
      } else {
        //主办部门 和 协办
        // 根据用户名去部门表查是否部门负责，如果是 返回

        let userName = localStorage.getItem("username");

        roleRes = await this.$dbquery(
          this.$http,
          "SELECT num FROM xtbg_congress_propose WHERE `status`='处理中' AND suggest_dept=(SELECT dept_name FROM sys_dept WHERE leader='" +
            userName +
            "')"
        );
        //主办部门

        if (roleRes.length > 0) {
          const dcls = roleRes.map((row) => row.num).join(",");
          if (roleRes.length > 0) {
            this.showNotice(
              "<div style='height:100px;'><br/>您有<span style='font-size:16px;color:red;'>" +
                roleRes.length +
                "</span>个提案需要处理" +
                "，编号为：[<span style='font-size:14px; color:#303133'>" +
                dcls +
                "</span>]，点击前往[提案系统-当前提案]<a href='/proposal_meetting' style='font-size:16px;color:red'>处理" +
                "</a></div>"
            );
          }
        } else {
          //协办
          roleRes = await this.$dbquery(
            this.$http,
            "SELECT x.num FROM xtbg_congress_propose x JOIN sys_dept d ON x.suggest_dept_other LIKE CONCAT('%', d.dept_name, '%') WHERE x.`status` = '处理中' AND d.leader = '" +
              userName +
              "'"
          );
          if (roleRes.length > 0) {
            const dcls = roleRes.map((row) => row.num).join(",");
            if (roleRes.length > 0) {
              this.showNotice(
                "<div style='height:100px;'><br/>您有<span style='font-size:16px;color:red;'>" +
                  roleRes.length +
                  "</span>个提案需要处理" +
                  "，编号为：[<span style='font-size:14px; color:#303133'>" +
                  dcls +
                  "</span>]，点击前往[提案系统-当前提案]<a href='/proposal_meetting' style='font-size:16px;color:red'>协办" +
                  "</a></div>"
              );
            }
          }
        }
      }
    },
  },
  async mounted() {
    let roleName = localStorage.getItem("roleName");

    let btn_login_status = document.getElementById("btn_login_status");

    btn_login_status.innerText = "退出";
    if (roleName != null) {
      //判断权限，如果是外籍 用户 则隐藏有些模块
      if (roleName.indexOf("外籍") > -1) {
        this.wjhidden = false;
      } else {
        this.wjhidden = true;
      }
    }

    //提案系统的通知

    this.noticeByRole(localStorage.getItem("userid"));
    //
  },
};
</script>

<style lang="scss">
.bny-main {
  padding-top: 5px;
  width: 1400px;
  height: auto;
  margin: 0px auto;
  background: #f2f2f2;

  .bny-main-left {
    width: 1000px;
    height: auto;
    /* background: #F00; */
    float: left;
  }
  .bny-main-right {
    width: 395px;

    height: auto;
    /* background: #ff0; */
    margin-left: 5px;
    float: left;
  }
}
</style>
