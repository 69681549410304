<template>
  <div class="bny_index-list">
    <div class="bny_index-list-title">
      <img :src="titlelogo" alt="" />
      <div style="height: 33px; border-bottom: 3px solid rgb(81 159 109); text-align: center;">{{ title }}</div>
      <div @click="moreClick">更多>></div>
    </div>
    <div class="bny_index-list-content">
      <!-- <el-table
        :data="tableData"
        stripe
        style="width: 1000px; background: transparent"
        @row-click="tableRowClick"
        :show-header="false"
      >
        <el-table-column
          v-for="item in TableColumns"
          :key="item.id"
          :prop="item.pname"
          :label="item.label"
          :width="item.width"
        >
        </el-table-column>
      </el-table> -->
      <div class="row-list-nwgg rowup"></div>
      <div class="row-list-nwgg rowup"></div>
      <div
        class="row-list-nwgg rowup"
        v-for="row in tableData"
        :key="row.id"
        @click="tableRowClick(row)"
        v-html="row.title"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index-line-item",
  props: {
    title: {
      type: String,
      default: "",
    },
    titlelogo: {
      type: String,
      default: "",
    },
    TableColumns: {
      type: Array,
      default: [],
      required: true,
    },
    tableData: {
      type: Array,
      default: [],
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    tableRowClick(row) {
      // console.log(this.title);
       //console.log(row);


      row.title = row.title.split("[")[0];
      localStorage.setItem("title", encodeURI(this.title));
      localStorage.setItem("showTitle", encodeURI(row.title));
      localStorage.setItem("deptName", row.deptName);
      this.$router.push({
        path: "/newsdetail",
        query: {
          id: row.id,
        },
      });
      // this.$router.push({
      //   path: "/newsdetail",
      //   query: {
      //     title: encodeURI(this.title),
      //     showTitle: encodeURI(row.title),
      //     id: row.id,
      //   },
      // });
    },
    moreClick() {
      if (this.title.indexOf("咨询平台") == -1) {
        this.$router.push({
          path: "/news",
          query: {
            name: this.title,
          },
        });
      } else {
        this.$router.push({
          path: "/advise",
          query: {
            name: this.title,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bny_index-list {
  height: auto;
  width: 385px;
  padding-left: 10px;
  //background: url('../../assets/imgs/m001.png') no-repeat;
  background-size: 100% 100%;
  margin: 5px 0px 0px 0px;
  .bny_index-list-title {
    font-weight: bold;
    height:30px;
    line-height: 30px;
    
    width: 385px;
   // background-color: rgb(81 159 109);
   
    img {
      width: 25px;
      height: 25px;
      margin: 4px 12px;
      float: left;
    }
    div {
      
      font-size: 16px;
      text-align: left;
      padding-left: 5px;
      color: #444;
      float: left;
      &:first-child{
        border-bottom: 2px solid rgb(81 159 109);
      }
      &:last-child {
        float: right;
        margin-right: 15px;
        cursor: pointer;
        font-size: 14px;
        font-weight:normal;
      }
    }
  }
  .bny_index-list-content {
    width: 385px;
    height: 320px;
    font-size: 14px;
    color: rgb(81 159 109);
    overflow: hidden;
    overflow: hidden;
    border-top: 1px solid rgb(81 159 109);
    /* background: #fafafa; */
    background: rgb(245, 247, 250);
    

  }
}
.row-list-nwgg {
  min-height: 50px;
  max-height: 100px;
  
  white-space: pre-wrap;
  text-align: left;
  color: #606266;
  padding: 0 10px;
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
    color: #519f6d;
  }
}

@-webkit-keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -307px, 0);
    transform: translate3d(0, -307px, 0);
  }
}
@keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -307px, 0);
    transform: translate3d(0, -307px, 0);
  }
}
.bny_index-list-content .rowup {
  -webkit-animation: 30s rowup linear infinite normal;
  animation: 30s rowup linear infinite normal;
  position: relative;
}
</style>
